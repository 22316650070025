/* В файле Footer.css */
.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px 0;
  }
  
  .footer-content {
    margin: 0 auto;
    max-width: 960px;
  }
  
  .footer-links {
    margin-top: 10px;
  }
  
  .footer-links a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  