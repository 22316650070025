/* В файле HeroSection.css */
.hero-section {
    text-align: center;
    padding: 50px 20px;
    background: #f8f8f8; /* Пример фона, его можно изменить */
  }
  
  .hero-section h1 {
    margin-bottom: 20px;
    font-size: 2.5em;
    color: #333; /* Цвет текста заголовка */
  }
  
  .hero-section p {
    margin-bottom: 30px;
    font-size: 1.2em;
    color: #666; /* Цвет текста подзаголовка */
  }
  
  .cta-button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff; /* Пример цвета кнопки */
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: #0056b3; /* Цвет кнопки при наведении */
  }
  