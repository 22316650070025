/* В файле NavigationMenu.css */
.navigation-menu {
    background-color: #333;
    overflow: hidden;
  }
  
  .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .menu-item {
    float: left;
  }
  
  .menu-item a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  .menu-item a:hover {
    background-color: #ddd;
    color: black;
  }
  